<template>
  <div class="flex justify-center items-center">
    <p class="text-mainblack">Powered by <span class="text-primary">Vitel Health</span></p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>