<template>
  <div>
    <nav-bar></nav-bar>
    <div class="relative">
      <router-view></router-view>
    </div>
    <Footer :class="[
    currentRouteName === 'home' || 
    currentRouteName === 'company-profile' ||
    currentRouteName === 'find-providers'
     ? 'absolute bottom-4 left-0 right-0' : 'py-4']" />
  </div>
</template>

<script>
import NavBar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'

export default {
  components: {
    Footer,
    NavBar
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

<style>

</style>