<template>
  <nav class="fixed w-full z-50 border-b border-lightgrey bg-white py-4 px-3 md:px-12 lato">
    <div class="flex justify-between items-center">
      <img src="@/assets/img/logo.svg" alt="logo" />
      <ul class="hidden md:flex items-center">
        <li class="pr-8">
          <router-link to="/">Profile</router-link>
        </li>
        <li class="pr-8">
          <router-link to="/company-profile">Company Profile</router-link>
        </li>
        <li class="pr-8">
          <router-link to="/find-providers">Find Providers</router-link>
        </li>
        <li>
          <a @click.prevent="logout" href="#">Logout</a>
        </li>
      </ul>
      <img class="block w-10 md:hidden" src="@/assets/img/more.png" alt="logo" @click="toggleMenu" />
        <transition  name="slide-fade" v-if="showMobileMenu">
          <div class="absolute top-20 right-0 px-10 py-10 h-screen shadow transition duration-150 ease-in-out bg-white md:hidden">
          <ul class="flex flex-col">
            <li class="pb-8">
              <router-link to="/">Profile</router-link>
            </li>
            <li class="pb-8">
              <router-link to="/company-profile">Company Profile</router-link>
            </li>
            <li class="pb-8">
              <router-link to="/find-providers">Find Providers</router-link>
            </li>
            <li>
              <a @click.prevent="logout" href="#">Logout</a>
            </li>
          </ul>
          </div>
        </transition>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showMobileMenu: false
    }
  },
  methods: {
    toggleMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout();
      this.$router.push({path: "/"});
    }
  }

}
</script>

<style>
a {
  color: #292929 !important;
  text-decoration: none !important;
  font-weight: 600 !important;
}

a:hover {
  color: #02E864 !important;
}

a.router-link-exact-active {
  color: #02E864 !important;
  border-bottom: none !important;
}

slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>